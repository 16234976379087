import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModelManager from 'src/common/ModelManager';
import PathRoute from 'src/common/PathRoute';
import useComponentVisible from 'src/hooks/useComponentVisible';
import useGetWidth from 'src/hooks/useGetWidth';
import { saveDefautConfig } from 'src/redux/actions/homeAction';
import siteService from 'src/services/siteService';
import Image from 'next/image';
import Head from 'next/head';
import Constants from 'src/common/Constants';
import Utils from 'src/common/Utils';
import { headerActionType } from 'src/redux/actions/actionTypes';
import countryService from 'src/services/countryService';
const Button = dynamic(() => import('src/components/Button'), { ssr: true });
const NearbyAcademy = dynamic(
    () => import('src/components/include/NearbyAcademy'),
    { ssr: true },
);

import { S3_BUCKET_NAME } from 'src/common/Environment';
import countries from 'src/common/Countries';
import { upperCase } from 'lodash';
import { appEnvironment, loginRedirect } from './const';

const LinkItem = (props) => {
    const { title, href, hideMenu, alt } = props;

    return (
        <li onClick={hideMenu} className="menu-link" style={props?.style || {}}>
            <Link href={href || ''} passHref scroll prefetch={false}>
                <a
                    style={{ color: props.isOrange === true ? '#EE7925' : '' }}
                    aria-label={alt}>
                    {title}
                </a>
            </Link>
        </li>
    );
};

function Header() {
    const dispatch = useDispatch();
    const router = useRouter();
    const academyLocation = router?.query?.franchise;
    const { listSite } = useSelector((state) => state.listSiteReducer);
    const { defaultTypeform } = useSelector((state) => state.homeReducer);
    const defaultAcademyss = listSite?.find(
        (e) => academyLocation === e.ms_alias,
    );
    const [menuMobile, setMenuMobile] = useState(false);
    const [fixHeader, setFixHeader] = useState(true);
    const [defaultAcademy, setDefaultAcademy] = useState({});
    const _isShowLogoHome = useGetWidth() <= 1060;
    const mobile = useGetWidth();
    const [isShowLogo, setIsShowLogo] = useState(mobile);
    const [showSelect, setShowSelect] = useState(false);
    const { isComponentVisible } = useComponentVisible(true);
    const defaultAcademyChange = useSelector(
        (state) => state.headerReducer.academy,
    );

    const [siteUS, setSiteUS] = useState([]);
    const headerReducer = useSelector((state) => state.headerReducer);
    const country = useSelector((state) => state.headerReducer.country);
    const countryForSEO = Utils.getCountryPathName(router.pathname);
    const countryForSEOreverse = Utils.getCountryPathNameReverse(
        router.pathname,
    );
    const countryForSEOAlwaysUK = Utils.getCountryPathNameUK(router.pathname);

    const [valueNationInput, setValueNationInput] = useState(
        country || countryService.getCountry(),
    );
    const routeAcademy = router?.query?.franchise;
    const displayRouteAcademy = listSite.find(
        (item) => routeAcademy === item?.ms_alias,
    );

    const list = [
        {
            title: 'Weekly Training',
            href: Utils.checkAcademy(defaultAcademy, country, {
                routeWithAlias: PathRoute.WeeklyTrainingWithAlias,
                routeWithCountry: PathRoute.WeeklyTraining,
            }),
            alt: 'Explore our weekly training sessions by clicking here.',
        },
        {
            title: 'Holiday Camps',
            href: Utils.checkAcademy(defaultAcademy, country, {
                routeWithAlias: PathRoute.HolidayCampWithAlias,
                routeWithCountry: PathRoute.HolidayCamp,
            }),
            alt: 'Check out our Holiday Camps and experience something new by clicking here.',
        },
        {
            title: 'Birthday Parties',
            href: Utils.checkAcademy(defaultAcademy, country, {
                routeWithAlias: PathRoute.BirthdayPartyWithAlias,
                routeWithCountry: PathRoute.BirthdayParty,
            }),
            alt: 'Explore our Birthday Parties and enjoy a new experience by clicking here.',
        },
    ];

    const options = countries.map((el) => {
        return {
            label: (
                <>
                    <el.flag /> {upperCase(el.country)}
                </>
            ),
            value: el.country,
        };
    });

    useEffect(() => {
        getListSiteUS();
    }, []);

    const getListSiteUS = async () => {
        const data = await siteService.getListSiteUS();

        if (data?.status === 200 && data?.data?.data?.lstSite) {
            setSiteUS(data?.data?.data?.lstSite);
        }
    };

    useEffect(() => {
        if (router?.asPath.includes('/us')) {
            localStorage.removeItem('defaultAcademy');
            setValueNationInput(Constants.US);
            dispatch({
                type: headerActionType.CHANGE_COUNTRY,
                country: Constants.US,
            });
            if (Object.values(siteUS).length === 1) {
                dispatch({
                    type: headerActionType.CHANGE_ACADEMY,
                    academy: siteUS[0],
                });
            }
            return;
        }
        dispatch({
            type: headerActionType.DELETE_ACADEMY,
            academy: {},
        });
        setValueNationInput(Constants.UK);
        dispatch({
            type: headerActionType.CHANGE_COUNTRY,
            country: Constants.UK,
        });
    }, [router?.asPath, siteUS]);

    useEffect(() => {
        if (!isComponentVisible && showSelect) {
            setShowSelect(false);
        }
    }, [isComponentVisible]);

    useEffect(() => {
        async function getDefaultTypeForm() {
            try {
                const res = await siteService.getHome();
                if (res?.data?.status === 200 && res?.data?.data) {
                    const { defaultConfig } = res.data.data;

                    defaultConfig &&
                        dispatch(
                            saveDefautConfig({ defaultConfig: defaultConfig }),
                        );
                }
            } catch (error) {
                console.log(error, 'error');
            }
        }

        const selectedAcademy = ModelManager.getLocation();
        if (!isEmpty(selectedAcademy)) {
            dispatch(
                saveDefautConfig({
                    defaultConfig: {
                        default_typeform_id: selectedAcademy.ms_typeform_id,
                        use_typeform: selectedAcademy.ms_use_typeform,
                    },
                }),
            );
        } else {
            getDefaultTypeForm();
        }

        if (isEmpty(selectedAcademy) && !isEmpty(defaultAcademyChange)) {
            setDefaultAcademy(defaultAcademyChange);
            return;
        }
        setDefaultAcademy(selectedAcademy);
    }, [headerReducer]);

    function hideMenu() {
        setMenuMobile(false);
    }

    function handleScroll() {
        const newPos = document.body.getBoundingClientRect().top;
        if (newPos < -200 && fixHeader) setFixHeader(false);
        if (newPos > -150 && fixHeader) setFixHeader(true);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const valueOption = useMemo(
        () => options.find((item) => item.value === valueNationInput) ?? {},
        [valueNationInput],
    );

    const renderHeader = () => {
        if (!isEmpty(defaultAcademy) && isEmpty(displayRouteAcademy)) {
            return defaultAcademy.ms_name;
        }
        if (!isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            localStorage.setItem(
                'defaultAcademy',
                JSON.stringify(displayRouteAcademy),
            );
            return displayRouteAcademy?.ms_name;
        }
        if (isEmpty(defaultAcademy) && !isEmpty(displayRouteAcademy)) {
            localStorage.setItem(
                'defaultAcademy',
                JSON.stringify(displayRouteAcademy),
            );
            return displayRouteAcademy?.ms_name;
        }

        return '';
    };

    const checkTitle = () => {
        if (!isEmpty(defaultAcademy)) {
            return 'TRY A FREE SESSION';
        }
        return 'BOOK A FREE SESSION';
    };

    const renderButton = () => {
        if (!isEmpty(listSite) && country === Constants.US) {
            if (Object?.values?.(listSite).length === 1) {
                const title = checkTitle();
                return (
                    <Button
                        style={{
                            fontSize: 14,
                            marginLeft: 40,
                            fontWeight: 500,
                            letterSpacing: 2.3,
                        }}
                        idTypeForm={
                            listSite[0]?.ms_use_typeform === 1
                                ? listSite[0]?.ms_typeform_id
                                : null
                        }
                        title={title}
                        onClick={() => {
                            global.bookTraining = {
                                siteSelected: listSite[0],
                            };

                            router.push(
                                PathRoute.BookTrialTrainingSuccessWithAlias(
                                    country,
                                    listSite[0].ms_alias,
                                ),
                            );
                        }}
                    />
                );
            }
            return;
        }
        if (!isEmpty(defaultAcademy)) {
            const title = `${
                defaultAcademy && defaultAcademy.ms_trial === 1
                    ? 'BOOK A TRIAL SESSION'
                    : 'TRY A FREE SESSION '
            } `;

            return (
                <Button
                    style={{
                        fontSize: 14,
                        marginLeft: 40,
                        fontWeight: 500,
                        letterSpacing: 2.3,
                    }}
                    idTypeForm={
                        defaultAcademy.ms_use_typeform === 1
                            ? defaultAcademy.ms_typeform_id
                            : null
                    }
                    title={title}
                    onClick={() => {
                        global.bookTraining = {
                            siteSelected: defaultAcademy,
                        };

                        router.push(
                            PathRoute.BookTrialTrainingSuccessWithAlias(
                                country,
                                defaultAcademy.ms_alias,
                            ),
                        );
                    }}
                />
            );
        }

        return (
            <Button
                style={{
                    fontSize: 14,
                    marginLeft: 40,
                    fontWeight: 500,
                    letterSpacing: 2.3,
                }}
                idTypeForm={
                    defaultTypeform.use_typeform === 1
                        ? defaultTypeform?.default_typeform_id
                        : null
                }
                onClick={() => {
                    global.bookTraining = {
                        siteSelected: defaultAcademy,
                    };

                    router.push(PathRoute.BookTrialTrainingSuccess(country));
                }}
                title={`Book a ${
                    defaultAcademy && defaultAcademy.ms_trial === 1
                        ? 'trial'
                        : 'free'
                } session`}
            />
        );
    };

    return (
        <>
            {router?.pathname.includes('[franchise]') ? (
                <></>
            ) : (
                <Head>
                    <link
                        rel="alternate"
                        href={`https://wemakefootballers.com${countryForSEO.country}`}
                        hrefLang={countryForSEO.lang}
                    />

                    <link
                        rel="alternate"
                        href={`https://wemakefootballers.com${countryForSEOreverse.country}`}
                        hrefLang={countryForSEOreverse.lang}
                    />

                    <link
                        rel="alternate"
                        href={`https://wemakefootballers.com${countryForSEOAlwaysUK.country}`}
                        hrefLang="x-default"
                    />
                </Head>
            )}
            <div
                className={`header ${fixHeader ? '' : 'fix-header'}`}
                style={{}}>
                <div className="head-top-mobile">
                    <div className="container displayFlex">
                        <Link href={PathRoute.Home(country)} prefetch={false}>
                            <Image
                                src="/static-file/images/logo.svg"
                                alt="Logo"
                                className={`logofix`}
                                width={160}
                                height={50}
                            />
                        </Link>
                        <ul className="menu-small">
                            <li className="menu-link">
                                <NearbyAcademy
                                    onChangeLocation={hideMenu}
                                    isHeader={true}
                                    defaultAcademyProps={defaultAcademyss}
                                />
                            </li>
                        </ul>
                        <button
                            className={`btn-button icon-menu ${
                                menuMobile ? 'show' : ''
                            }`}
                            onClick={() => setMenuMobile(!menuMobile)}>
                            menu
                        </button>
                    </div>
                </div>

                <div className={`navi ${menuMobile ? 'show' : ''}`}>
                    <div className="menu-top">
                        <div className="menu-left">
                            <Link
                                href={PathRoute.Home(country)}
                                scroll
                                onClick={hideMenu}
                                prefetch={false}>
                                <img
                                    src={`${S3_BUCKET_NAME}/static-files/images/logo.svg`}
                                    width={160}
                                    height={43}
                                    loading="lazy"
                                    className="logo"
                                    alt="We Make Footballers Logo"
                                />
                            </Link>
                        </div>

                        <ul className="menu">
                            {list?.map((item) => (
                                <LinkItem
                                    title={item.title}
                                    hideMenu={hideMenu}
                                    href={item.href}
                                    key={item.title}
                                />
                            ))}
                            {isShowLogo && <li>{renderButton()}</li>}
                        </ul>
                    </div>
                    <div className="menu-down">
                        <div>
                            {isShowLogo && (
                                <div className="menu-small widget-header">
                                    <a
                                        href="https://uk.trustpilot.com/review/wemakefootballers.com"
                                        target="_blank">
                                        <img
                                            src={`${S3_BUCKET_NAME}/static-files/images/header_trustpilot.png`}
                                            width={290}
                                            height={25}
                                            loading="lazy"
                                            alt="Trust pilot link"
                                        />
                                    </a>
                                </div>
                            )}
                            <ul className="menu-small headerMobileList">
                                {!isEmpty(renderHeader()) && (
                                    <LinkItem
                                        style={{ marginLeft: 0 }}
                                        isOrange={!menuMobile}
                                        title={renderHeader()}
                                        hideMenu={hideMenu}
                                        href={PathRoute.AliasHome(
                                            country,
                                            defaultAcademy.ms_alias,
                                        )}
                                    />
                                )}

                                <LinkItem
                                    title={'Find A Class'}
                                    hideMenu={hideMenu}
                                    href={PathRoute.FindClass(country)}
                                />

                                <LinkItem
                                    title={'About'}
                                    hideMenu={hideMenu}
                                    href={PathRoute.AboutUs(country)}
                                />

                                <LinkItem
                                    title={'News'}
                                    hideMenu={hideMenu}
                                    href={Utils.checkAcademy(
                                        defaultAcademy,
                                        country,
                                        {
                                            routeWithAlias:
                                                PathRoute.HomeNewsWithAlias,
                                            routeWithCountry:
                                                PathRoute.HomeNews,
                                        },
                                    )}
                                />

                                <LinkItem
                                    href={`${
                                        !isEmpty(defaultAcademy)
                                            ? PathRoute.JoinUsWithAlias(
                                                  country,
                                                  defaultAcademy?.ms_alias,
                                              )
                                            : PathRoute.JoinUs(country)
                                    }`}
                                    hideMenu={hideMenu}
                                    title={'Join Us'}
                                />

                                <LinkItem
                                    title={'Locations'}
                                    hideMenu={hideMenu}
                                    href={PathRoute.Location(country)}
                                />

                                {isShowLogo && (
                                    <>
                                        <li>
                                            <NearbyAcademy
                                                onChangeLocation={hideMenu}
                                                defaultAcademyProps={
                                                    defaultAcademyss
                                                }
                                            />
                                        </li>
                                        <li className="login">
                                            <a
                                                href={process.env.NEXT_PUBLIC_ENV === appEnvironment.Stage ? loginRedirect.Stage : loginRedirect.Production }
                                                target="_blank"
                                                rel="noreferrer">
                                                Login
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
